import { Fade } from 'components/fade'
import React from 'react'
import '../_styles.scss'
import { FeedArticle } from '../types'

interface ArticleCardProps {
  article: FeedArticle,
  descriptionCap?: number,
  readMoreText?: string,
}

export const Article = (props: ArticleCardProps) => {
  const {article, descriptionCap, readMoreText} = props
  const description = !article.description
  ? ''
  : descriptionCap ? article.description.slice(0, descriptionCap) : article.description
  return <Fade>
  <div className="article">
    {article.image && <div className="article-image-wrapper">
      <a href={article.url} target="_blank" rel="noopener">
      <div className="image-hider">
        <img className="source-article-image" src={article.image} />
      </div>
      </a>
    </div>}
    <div className="article-text-col">
      <div>
        <div className="article-title">
          <a className="article-title-link"
            target="_blank"
            rel="noopener"
            href={article.url}>
            {article.title}
          </a>
        </div>
      </div>
      <div>
        <div className="article-content">{description}</div>
      </div>
      <div>
        <div className="read-more">
          <a className="read-more-link"
            href={article.url}
            target="_blank"
            rel="noopener">
            <strong>{readMoreText ? readMoreText : 'Read More'}</strong>
          </a>
        </div>
      </div>
    </div>
  </div>
</Fade>
}
