import { YoutubeLogo } from 'components/icons/youtube'
import { preventDefault, preventDefaultThen } from 'components/link'
import * as Loading from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import React from 'react'
import { Button } from 'reactstrap'
import * as Redux from 'redux'
import { SourceTypes } from 'shared_server_client/constants'
import { injectReducer } from 'store/index'
import { DEFAULT_ARTICLE_DESCRIPTION_CAP } from '../constants'
import { FeedArticle } from '../types'
import { Article } from '../utility/components'
import { isValidSourceName, postAddSource } from '../utility/utils'
import './_styles.scss'
import * as Constants from './constants'

interface Source {
  community_id?: string,
  identifier: string,
  name: string,
  type: string,
}

type AddSourceProps = GenericRedux.AllComponentProps<Source>

interface AddSourceState {
  existingSources: any[],
  isLoading: boolean,
  name: string,
  pageFeeds: any[],
  pageFeedsFound: boolean,
  selectedCommunity: string,
  selectedChannel: any,
  channelId: string,
}

class ContentPoolAddYoutubeSourceComponent extends RasaReactComponent<AddSourceProps, AddSourceState> {
  public static contextType = RasaContext;
  constructor(props: AddSourceProps) {
    super(props, 'communitySource', {
      existingSources: [],
      isLoading: false,
      name: '',
      pageFeeds: [],
      pageFeedsFound: true,
      selectedChannel: {},
      selectedCommunity: null,
      channelId: '',
    })
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.loadRecord(activeCommunity.communityId, null)
      new Dataset()
        .loadCommunityDataset('communitySources', activeCommunity.communityId)
        .then((response) => {
          this.setState({
            existingSources: response[0] || [],
            selectedCommunity: activeCommunity.communityId,
          })
        })
    })
  }

  public render = () => {
    return <div className={`${this.context.store.getState().config.isMobile ? 'add-youtube-wrapper-mobile' : 'add-youtube-wrapper'}`}>
      <h2 className="sources-heading">Sources</h2>
      <div className="section-header-text">Add New YouTube Page</div>
      <p className="section-detail-text">Add a YouTube Channel page and we will pull the videos.</p>
      {this.state.pageFeeds.length === 0 &&
        <div className="youtube sources">
          <div className="image">
            <YoutubeLogo svgheight={16.48}/>
          </div>
          <div className="words">
            <h2>YouTube</h2>
            <p>Enter the channel url or id.</p>
            <div className="input-area">
              <form onSubmit={preventDefaultThen(() => this.getFeed(this.state))}>
                <input autoFocus disabled={this.state.isLoading} className="field-textbox"
                  value={this.state.channelId}
                  onChange={(e: any) => this.setState({ channelId: e.target.value })}/>
                <Button disabled={this.state.channelId.trim() === '' || this.state.isLoading}>Next</Button>
              </form>
            </div>
            {!this.state.pageFeedsFound && <span className="warning">We could not locate this Channel</span>}
          </div>
        </div>
      }
      {this.state.pageFeeds.length > 0 &&
        <div className="youtube confirm sources">
          <div className="image">
            <YoutubeLogo svgheight={16.48}/>
          </div>
          <div className="words">
            <h2>YouTube</h2>
            <p>
              <strong className="feed-result-text">We found the channel, {this.state.name}.</strong>
            </p>
            <p>Specify how you would like this source to be named in your newsletter and hit confirm.</p>
            {!isValidSourceName(this.state.existingSources, this.state.name) &&
            <div className="invalid-source-name-container">You already have a source with this name</div>}
            <div className="input-area">
              <form onSubmit={preventDefault()}>
                <input autoFocus className="field-textbox"
                  value={this.state.name}
                  onChange={(e: any) => this.setState({name: e.target.value})}
                />
                <Button onClick={() => this.addSource({
                  community_id: this.context.user.activeCommunity.communityId,
                  identifier: this.state.selectedChannel.feedUrl,
                  name: this.state.name,
                  type: SourceTypes.youtube})}
                  disabled={this.state.isLoading ||
                    !isValidSourceName(this.state.existingSources, this.state.name)}>Confirm</Button>
                <Button className="change" onClick={() => this.changeSelection()}>Cancel</Button>
              </form>
            </div>
          </div>
        </div>
      }
      {this.state.isLoading && <Loading.Loading size={Loading.SIZES.LARGE}/>}
      {this.state.pageFeeds.length > 0 &&
        <div className={`${this.context.store.getState().config.isMobile ? 'recent-videos-mobile' : 'recent-videos'}`}>
          <h4>Recent Videos</h4>
          {this.state.pageFeeds.slice(0, 5).map((feed: FeedArticle, i: number) =>
          <Article readMoreText="Watch Now" key={i} article={feed} descriptionCap={DEFAULT_ARTICLE_DESCRIPTION_CAP}/>)}
          {!this.state.pageFeedsFound && <span className="warning">We could not find any video.</span>}
        </div>
      }
    </div>
  }

  private getFeed(state: AddSourceState) {
    this.setState({isLoading: true}, () =>
      AjaxWrapper.ajax(AjaxWrapper.getServerUrl() + `/youtube/${this.state.selectedCommunity}/channel/feeds?q=${state.channelId}&i=${state.selectedCommunity}&a=dashboard`,
        HttpMethod.GET,
        {},
      )
      .then((pageFeeds) => {
        if (pageFeeds) {
          this.setState({
            pageFeeds,
            pageFeedsFound: pageFeeds.length > 0,
            isLoading: false,
            selectedChannel: pageFeeds.length > 0 ? {
              author: pageFeeds[0].author,
              feedUrl: pageFeeds[0].feedUrl,
            } : {},
            name: pageFeeds.length > 0 ? pageFeeds[0].author : '',
          })
        }
      })
      .catch(() => this.setState({
        pageFeedsFound: false,
        isLoading: false,
        name: '',
      })),
    )
  }
  private changeSelection() {
    this.setState({
      pageFeeds: [],
      pageFeedsFound: true,
      selectedChannel: {},
    })
  }

  private addSource = (source: Source) => {
    this.setState({communityId: this.context.user.activeCommunity.communityId}, () => {
      this.props.propertiesChanged({
        ...source,
        community_id: this.context.user.activeCommunity.communityId,
      })
      this.saveRecord(null).then(() => {
        postAddSource(this.context, 'youtube', source.identifier, this.props.push)
      })
    })
  }
}

export const ContentPoolAddYoutubeSource = GenericRedux.createConnect(ContentPoolAddYoutubeSourceComponent, Constants.REDUX_STORE_HOME)
injectReducer(
  Constants.REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(Constants.REDUX_STORE_HOME, {}),
  }),
)
